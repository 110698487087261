// This is the runtime configuration being used for local development
// where authorization is usually mocked

import { DEVICE_SYSTEM_STATE_SERVICE } from '~/features/base/constants/services';

export const localConfig = {
    id: 'env.local',
    region: 'EU',
    backend: {
        DEVICE_MANAGEMENT_SERVICE: 'http://localhost:443/api/mdmservice',
        DEVICE_SYSTEM_STATE_SERVICE: 'http://localhost:443/api/tbm3systemstatenotification',
        DEVICE_LOG_DOWNLOAD_SERVICE: 'http://localhost:443/api/tbm3logdownloadv1/devices',
        ATLASSIAN_CONNECTOR: 'http://localhost:443/api/mdmotaac',
        REPORTS_BILLING_CHARTS_DATA_SERVICE: 'http://localhost:443/api/mdm/reporting/v1/traffic/search',
        REPORTS_BILLING_CHARTS_DATA_VERSIONS_SERVICE: 'https://6182843e84c2020017d89efa.mockapi.io/dataTraffic/dataTrafficTypes',
    },
    homeRoute: `${window.location.origin}`,
    appMenuUri: 'http://localhost:8040/#app-menu',
    login: {
        authority: `${window.location.origin}/oauth`,
        mockAuthorization: true,
        mockLocale: 'de-DE',
        preventRedirect: true,
        redirectUri: `${window.location.origin}/redirect.html`,
        silentRedirectUri: `${window.location.origin}/redirect.html`,
    },
    logoutUri: `${window.location.origin}`,
    packageVersionDownloadBaseUrl: 'https://transfer.devgateway.eu/release/packages/',
    packageVersionInstallerBaseUrl: 'https://transfer.devgateway.eu/release/packages/app-installer_2020.09.04',
    networkCodes: {},
    networkCodesWhiteList: []
};
