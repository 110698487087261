import React from "react";
import {PersistedAdaptOrder} from "~/api/models/persistedAdaptOrder";
import PropertiesTable from "~/features/base/components/table/PropertiesTable";
import {groupRowDescriptors} from "~/features/groups/constants/groupRowDescriptors";
import DefaultWhiteColumn from "~/features/base/components/DefaultWhiteColumn";
import {AdaptOrderDetailsDescriptors} from "~/features/adaptOrders/constants/AdaptOrderDetailsDescriptors";

interface OrderDetailsProps {
    order: PersistedAdaptOrder;
}

const AdaptOrderDetails: React.FC<OrderDetailsProps> = ({order}: OrderDetailsProps) => {

    return (
        <div className="order-details">
            <DefaultWhiteColumn className='padding-top-20 padding-bottom-20'>
                <PropertiesTable showHeader={false} data={order} rowDescriptors={AdaptOrderDetailsDescriptors} />
            </DefaultWhiteColumn>
        </div>
    );
};

export default AdaptOrderDetails;
