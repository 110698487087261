import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import DeviceFilterForm from './DeviceFilterForm';
import { hotkeys } from 'react-keyboard-shortcuts';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';

export class DeviceFilterDialog extends PureComponent {
    onHwVariantFilterChange = ({ suggestionId }) => {
        this.updateFilter({
            hwVariant: suggestionId,
        });
    };
    onDeviceStatusNamesChange = ({ deviceStatusNames }) => {
        this.updateFilter({
            deviceStatusNames,
        });
    };
    onDeviceStatusTypeChange = ({ deviceStatusType }) => {
        this.updateFilter({
            deviceStatusType,
        });
    };
    onHwVariantListFilterChange = (hwVariantList) => {
        this.updateFilter(
            hwVariantList,
        );
    };
    onDistroVersionFilterChange = ({ suggestionId }) => {
        this.updateFilter({
            distroVersion: suggestionId,
        });
    };
    onExcludeDistroVersionsFilterChange = ({ excludeDistroVersions }) => {
        this.updateFilter({
            excludeDistroVersions,
        });
    };
    onBaseSwVersionFilterChange = ({ suggestionId }) => {
        this.updateFilter({
            swVersion: suggestionId,
        });
    };
    onWhitelistingInfoChange = (info) => {
        const {
            isValidDeviceWhitelist = true,
            deviceWhitelist,
            isValidVinList = true,
            vinList,
            vehicleGroupWhitelist,
            isValidDeviceBlacklist = true,
            deviceBlacklist,
            vehicleGroupBlacklist,
        } = info;
        const filterUpdate = {
            vehicleGroupWhitelist,
            vehicleGroupBlacklist,
            deviceWhitelist: isValidDeviceWhitelist ? deviceWhitelist : undefined,
            vinList: isValidVinList ? vinList : undefined,
            deviceBlacklist: isValidDeviceBlacklist ? deviceBlacklist : undefined,
        };
        this.setState({
            isValidDeviceWhitelist: isValidDeviceWhitelist,
            isValidVinList: isValidVinList,
            isValidDeviceBlacklist: isValidDeviceBlacklist,
        });
        this.updateFilter(filterUpdate);
    };
    onVehicleSparePartNumberChange = ({ suggestionId }) => {
        this.updateFilter({
            vehicleManufacturerSparePartNumber: suggestionId,
        });
    };
    onDeviceTypeFilterChange = ({ deviceType }) => {
        this.updateFilter({
            deviceType,
        });
    };
    onInCustomerFleetFilterChange = ({ inCustomerFleet }) => {
        this.updateFilter({
            inCustomerFleet,
        });
    };
    onUpdatesActiveFilterChange = ({ updatesActive }) => {
        this.updateFilter({
            updatesActive,
        });
    };
    onVehicleVariantFilterChange = (vehicleVariants) => {
        this.updateFilter(
            vehicleVariants,
        );
    };
    onTestReleasesActiveFilterChange = ({ testReleasesActive }) => {
        this.updateFilter({
            testReleasesActive,
        });
    };

    onLastEssentialImageReportedChange = (lastEssentialImageReported) => {
        this.updateFilter({
            lastEssentialImageReportedId: lastEssentialImageReported.id,
            lastEssentialImageReportedVersion: lastEssentialImageReported.version,

        });
    };

    onClearFilters = () => {
        this.props.clearFilter();
    };
    updateFilter = (filters) => {
        this.props.updateFilter(filters);
    };
    // eslint-disable-next-line camelcase
    hot_keys = {
        'esc': {
            priority: 1,
            handler: () => {
                this.props.toggleTableFilterDialog();
            },
        },
        'return': {
            priority: 1,
            handler: () => {
                this.props.toggleTableFilterDialog();
            },
        },
    };

    renderTitle() {
        return <FormattedMessage id='intl-msg:filter'/>;
    }

    renderBody() {
        return (
            <DeviceFilterForm onHwVariantFilterChange={this.onHwVariantFilterChange}
                              onHwVariantListFilterChange={this.onHwVariantListFilterChange}
                              onDeviceStatusNamesChange={this.onDeviceStatusNamesChange}
                              onDeviceStatusTypeChange={this.onDeviceStatusTypeChange}
                              onDeviceTypeFilterChange={this.onDeviceTypeFilterChange}
                              onDistroVersionFilterChange={this.onDistroVersionFilterChange}
                              onBaseSwVersionFilterChange={this.onBaseSwVersionFilterChange}
                              onWhitelistingInfoChange={this.onWhitelistingInfoChange}
                              onAddVehicleSparePartNumber={this.onAddVehicleSparePartNumber}
                              onVehicleSparePartNumberChange={this.onVehicleSparePartNumberChange}
                              onInCustomerFleetFilterChange={this.onInCustomerFleetFilterChange}
                              onUpdatesActiveFilterChange={this.onUpdatesActiveFilterChange}
                              onVehicleVariantFilterChange={this.onVehicleVariantFilterChange}
                              onTestReleasesActiveFilterChange={this.onTestReleasesActiveFilterChange}
                              onLastEssentialImageReportedChange={this.onLastEssentialImageReportedChange}
            />
        );
    }

    renderFooter() {
        return (
            <div className={'btn-toolbar justify-content-end'}>
                <a id='clear-filters-button'
                   className='btn btn-default'
                   onClick={this.onClearFilters}>
                    <span className='rioglyph rioglyph-trash' aria-hidden='true'></span>&nbsp;
                    <FormattedMessage id='intl-msg:clearFilters'/>
                </a>
                <button className='btn btn-default' onClick={this.props.toggleTableFilterDialog}>
                    <FormattedMessage id='intl-msg:ok'/>
                </button>
            </div>
        );
    }

    render() {
        const { toggleTableFilterDialog } = this.props;
        const title = this.renderTitle();
        const body = this.renderBody();
        const footer = this.renderFooter();
        return (
            <Dialog className='device-editor-dialog'
                    show={true}
                    showCloseButton={true}
                    onHide={toggleTableFilterDialog}
                    title={title}
                    body={body}
                    footer={footer}
                    useOverflow
            />
        );
    }
}

export default hotkeys(DeviceFilterDialog);

DeviceFilterDialog.defaultProps = {
    // props
    defaultFilters: {},
    // functions
    updateFilter: noop,
    clearFilter: noop,
    toggleTableFilterDialog: noop,
};

DeviceFilterDialog.propTypes = {
    // props
    defaultFilters: PropTypes.object,
    // functions
    updateFilter: PropTypes.func,
    clearFilter: PropTypes.func,
    toggleTableFilterDialog: PropTypes.func,
};
