import keys from 'lodash/fp/keys';
import omitBy from 'lodash/fp/omitBy';
import isUndefined from 'lodash/fp/isUndefined';
import split from 'lodash/fp/split';
import isEmpty from 'lodash/fp/isEmpty';

import { toDistroVersion, toDistroVersions, toSemanticVersion } from '~/features/base/utils/versionNumberConverter';
import { LastReportedEssentialImage } from '~/features/deliverables/utils/LastReportedEssentialImage';

const stringToBool = string => {
    if (string === 'true') {
        return true;
    }
    if (string === 'false') {
        return false;
    }
    if (typeof string === 'boolean') {
        return string;
    }
    return undefined;
};

const getWithDistroVersion = ({ distroVersion }) => {
    if (distroVersion === 'unknown') {
        return false;
    }
    if (distroVersion === 'other') {
        return true;
    }
    return undefined;
};
const getSwVersion = ({ swVersion }) => {
    const hasBaseSwVersion = swVersion &&
        (swVersion !== 'unknown') && (swVersion !== 'other');
    return hasBaseSwVersion ? toSemanticVersion(swVersion) : undefined;
};
const getDistroVersion = ({ distroVersion }) => {
    const hasDistroVersion = distroVersion &&
        (distroVersion !== 'unknown') && (distroVersion !== 'other');
    return hasDistroVersion ? toDistroVersion(distroVersion) : undefined;
};
const getExcludedDistroVersions = ({ /*distroVersion,*/ excludeDistroVersions }) => {
    // Fixed MANMDM-2189 FE: Device filter: excludedDistroVersions not always sending
    const splittedExcludedDistroVersions = excludeDistroVersions ? split(',', excludeDistroVersions) : undefined;
    return (/*distroVersion === 'other' &&*/ splittedExcludedDistroVersions) ?
        toDistroVersions(splittedExcludedDistroVersions) : undefined;
};
const getSerialNumbers = ({ serialNumbers }) => {
    return serialNumbers ? split(',', serialNumbers) : undefined;
};
const getVINs = ({ vinList }) => {
    return vinList ? split(',', vinList) : undefined;
};
const getWhitelistingInfo = ({ vehicleGroupWhitelist, deviceWhitelist, vehicleGroupBlacklist, deviceBlacklist }) => {
    const splittedVehicleGroupWhitelist = vehicleGroupWhitelist ? split(',', vehicleGroupWhitelist) : undefined;
    const splittedDeviceWhitelist = deviceWhitelist ? split(',', deviceWhitelist) : undefined;
    const splittedVehicleGroupBlacklist = vehicleGroupBlacklist ? split(',', vehicleGroupBlacklist) : undefined;
    const splittedDeviceBlacklist = deviceBlacklist ? split(',', deviceBlacklist) : undefined;

    const containsWhitelist = !!(splittedVehicleGroupWhitelist || splittedDeviceWhitelist);
    const containsBlacklist = !!(splittedVehicleGroupBlacklist || splittedDeviceBlacklist);
    const whitelistedForAll = (containsBlacklist && !containsWhitelist) ? true : undefined;

    const whitelistingInfo = omitBy(isUndefined, {
        whitelistedForAll,
        vehicleGroupWhitelist: splittedVehicleGroupWhitelist,
        deviceWhitelist: splittedDeviceWhitelist,
        vehicleGroupBlacklist: splittedVehicleGroupBlacklist,
        deviceBlacklist: splittedDeviceBlacklist,
    });
    //whitelistingInfo.vehicleGroupWhitelist = ['LATAM @ 26 Nov'];
    //whitelistingInfo.vehicleGroupWhitelist = ['OTA 4'];
    //whitelistingInfo.deviceWhitelist = ['180427-0169', '180427-0168'];
    //whitelistingInfo.vehicleGroupBlacklist = ['LATAM @ 26 Nov'];
    //whitelistingInfo.deviceBlacklist = ['180427-0169', '180427-0168'];
    return keys(whitelistingInfo).length ? whitelistingInfo : undefined;
};

const getVehicleVariantsWhitelistInfo = ({ vehicleVariantsWhitelist }) => {
    //trick to filter only truE variant vehicles
    if (vehicleVariantsWhitelist === true) {
        return ['true'];
    }
    if (Array.isArray(vehicleVariantsWhitelist)) {
        return vehicleVariantsWhitelist;
    } else if (!isEmpty(vehicleVariantsWhitelist)) {
        return [vehicleVariantsWhitelist];
    } else {
        return undefined;
    }
};

const getHardwareVariantList = ({ hwVariantList }) => {
    if (Array.isArray(hwVariantList)) {
        return hwVariantList;
    } else if (!isEmpty(hwVariantList)) {
        return [hwVariantList];
    } else {
        return undefined;
    }
};

const getDeviceStatusNames = ({ deviceStatusNames }) => {
    if (Array.isArray(deviceStatusNames)) {
        return deviceStatusNames;
    } else if (!isEmpty(deviceStatusNames)) {
        return [deviceStatusNames];
    } else {
        return undefined;
    }
};
export const isSerialNumber = (value) => {
    return Number.parseInt(value[0]) || value[0].toLowerCase() === 'c';
};
// TODO Think about combining this with filterOptions
export const queryToSearchCriteria = (query) => {
    const {
        serialNumberPrefix, vinPrefix, hwVariant, vehicleManufacturerSparePartNumber, deviceType, deviceStatusType,
        testReleasesActive,
        inCustomerFleet,
        updatesActive,
        lastCheckForUpdateAfter,
        lastEssentialImageReportedId,
        lastEssentialImageReportedVersion,
    } = query;
    return omitBy(isUndefined, {
        serialNumberPrefix,
        vinPrefix,
        hwVariantPrefix: hwVariant,
        hwVariantList: getHardwareVariantList(query),
        vehicleManufacturerSparePartNumber: vehicleManufacturerSparePartNumber,
        type: deviceType,
        status: deviceStatusType,
        checkNames: getDeviceStatusNames(query),
        withDistroVersion: getWithDistroVersion(query),
        softwareVersion: getSwVersion(query),
        distroVersion: getDistroVersion(query),
        excludedDistroVersions: getExcludedDistroVersions(query),
        serialNumbers: getSerialNumbers(query),
        vinList: getVINs(query),
        whitelistingInfo: getWhitelistingInfo(query),
        vehicleVariants: getVehicleVariantsWhitelistInfo(query),
        testReleasesActive: stringToBool(testReleasesActive),
        inCustomerFleet: stringToBool(inCustomerFleet),
        updatesActive: stringToBool(updatesActive),
        lastCheckForUpdateAfter,
        lastEssentialImageReported: LastReportedEssentialImage.create(lastEssentialImageReportedId, toSemanticVersion(lastEssentialImageReportedVersion)),
    });
};

