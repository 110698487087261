import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import {FormattedMessage} from 'react-intl';
import size from 'lodash/fp/size';
import noop from 'lodash/fp/noop';

import classNames from 'classnames';

import {EVENTS_PATH, PARAMETERS_PATH, SYSTEM_STATE_NOTIFICATIONS_PATH} from '~/features/base/constants/routes';
import {connect} from 'react-redux';
import {isDiagLogFileManagerSelector} from '~/features/user/selectors/permissionSelectors';
import {
    canReadControlDevicesSelector,
    controlDeviceEligibleDeliverablesSizeSelector,
    controlDeviceEligibleFileVersionsSelector,
    controlDeviceEligiblePackageVersionsSelector,
    getSelectedTabIdSelector,
} from '~/features/devices/selectors/controlDeviceSelectors';
import DeviceSystemParametersDialog from '~/features/devices/components/dialogs/DeviceSystemParametersDialog';
import {canCreateDeviceActionsSelector} from '~/features/deviceLogs/selectors/deviceActionSelectors';
import {canReadSysParamDefinitionsSelector} from '~/features/sysParams/selectors/sysParamDefinitionSelectors';
import {
    ADAPT_ORDERS,
    DELIVERABLES,
    DETAILS,
    FILES,
    LOGS,
    PACKAGES,
    REPORT,
} from '~/features/devices/constants/deviceDetailsTabs';
import {setSelectedTabDeviceDetailsSidebar} from '~/features/devices/actions/ui/controlDeviceDetailsActions';
import DeviceUpdateEventsDialog from '~/features/devices/components/dialogs/DeviceUpdateEventsDialog';
import DeviceSystemStatesDialog from '~/features/devices/components/dialogs/DeviceSystemStatesDialog';
import {CM4} from '~/features/devices/constants/deviceTypes';
import {env} from '~/env';
import {isLatamEnv} from '~/features/base/utils/isLatamEnv';

export class DeviceDetailsTabs extends PureComponent {
    constructor() {
        super();
        this.state = {
            showSystemParametersDialog: false,
            showSystemStatesDialog: false,
            showUpdateEventsDialog: false,
        };
    }

    renderNavigationItem = (tabId, currentTabId, label, badgeValue) => {
        const className = {
            active: currentTabId === tabId,
        };
        return (
            <li key={tabId} className={classNames(className)}>
                <a class='nav-link' onClick={() => this.props.setSelectedTabDeviceDetailsSidebar(tabId)}>
                    <FormattedMessage id={label}/>
                    {
                        badgeValue ? <span className='badge margin-left-5'>{badgeValue}</span> : null
                    }
                </a>
            </li>
        );
    };

    renderNavigation = () => {
        const {
            canReadSysParamDefinitions,
            controlDeviceEligiblePackageVersions,
            controlDeviceEligibleFileVersions,
            controlDeviceEligibleCM4Size,
            serialNumber,
            selectedTabId,
            deviceType,
        } = this.props;

        const { showSystemParametersDialog, showSystemStatesDialog, showUpdateEventsDialog } = this.state;
        const amountEligiblePackageVersions = size(controlDeviceEligiblePackageVersions);
        const amountEligibleFileVersions = size(controlDeviceEligibleFileVersions);
        const amountEligibleCM4Versions = controlDeviceEligibleCM4Size;
        //toDO: Remove env.runtimeConfig.id ==='env.production.test' after having adapt orders available in production
        const showAdaptOrdersTab = deviceType === CM4 && !isLatamEnv && env.runtimeConfig.id === 'env.production.test';

        return (
            <ul className='nav nav-pills'>
                {this.renderNavigationItem(DETAILS, selectedTabId, 'intl-msg:controlDeviceDetails')}
                {deviceType !== CM4 && this.renderNavigationItem(PACKAGES, selectedTabId, 'intl-msg:packages',
                    amountEligiblePackageVersions)}
                {deviceType !== CM4 && this.renderNavigationItem(FILES, selectedTabId, 'intl-msg:files',
                    amountEligibleFileVersions)}
                {deviceType === CM4 && this.renderNavigationItem(DELIVERABLES, selectedTabId, 'intl-msg:deliverables', amountEligibleCM4Versions)}
                <li key={EVENTS_PATH} onClick={this.toggleUpdateEventsDialog}>
                    <FormattedMessage id={'intl-msg:updateEvents'}/>
                </li>
                {showUpdateEventsDialog && <DeviceUpdateEventsDialog serialNumber={serialNumber}
                                                                     toggleUpdateEventsDialog={this.toggleUpdateEventsDialog}/>}
                {this.renderNavigationItem(REPORT, selectedTabId, 'intl-msg:report')}
                {this.renderNavigationItem(LOGS, selectedTabId, 'intl-msg:logs')}
                {showAdaptOrdersTab &&
                    this.renderNavigationItem(ADAPT_ORDERS, selectedTabId, 'Adapt Orders')}
                {deviceType !== CM4 && canReadSysParamDefinitions &&
                    <li key={PARAMETERS_PATH} onClick={this.toggleSystemParametersDialog}>
                        <FormattedMessage id={'intl-msg:sysParams'}/>
                    </li>
                }
                {deviceType !== CM4 && showSystemParametersDialog &&
                    <DeviceSystemParametersDialog serialNumber={serialNumber}
                                                  toggleSystemParametersDialog={this.toggleSystemParametersDialog}/>}
                {
                    < li key={SYSTEM_STATE_NOTIFICATIONS_PATH} onClick={this.toggleSystemStatesDialog}>
                        <FormattedMessage id={'intl-msg:apps.deviceState.title'}/>
                    </li>}
                {showSystemStatesDialog && <DeviceSystemStatesDialog serialNumber={serialNumber}
                                                                     deviceType={deviceType}
                                                                     toggleSystemStatesDialog={this.toggleSystemStatesDialog}/>}
            </ul>
        );
    };

    toggleSystemParametersDialog = () => {
        this.setState({ showSystemParametersDialog: !this.state.showSystemParametersDialog });
    };

    toggleSystemStatesDialog = () => {
        this.setState({ showSystemStatesDialog: !this.state.showSystemStatesDialog });
    };

    toggleUpdateEventsDialog = () => {
        this.setState({ showUpdateEventsDialog: !this.state.showUpdateEventsDialog });
    };

    render() {
        const navigation = this.renderNavigation();
        return (
            <div className='device-details-tabs row margin-bottom-20'>
                <div className='col-12 height-100pct'>
                    {navigation}
                </div>
            </div>
        );
    }
}

export const mapStateToProps = (state, ownProps) => {
    return {
        isDeviceLogsUser: isDiagLogFileManagerSelector(state),
        controlDeviceEligiblePackageVersions: controlDeviceEligiblePackageVersionsSelector(state, ownProps),
        controlDeviceEligibleFileVersions: controlDeviceEligibleFileVersionsSelector(state, ownProps),
        controlDeviceEligibleCM4Size: controlDeviceEligibleDeliverablesSizeSelector(state),
        canReadControlDevices: canReadControlDevicesSelector(state),
        canCreateDeviceActions: canCreateDeviceActionsSelector(state),
        canReadSysParamDefinitions: canReadSysParamDefinitionsSelector(state),
        selectedTabId: getSelectedTabIdSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        setSelectedTabDeviceDetailsSidebar: (tabId) => {
            dispatch(setSelectedTabDeviceDetailsSidebar(tabId));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceDetailsTabs);

DeviceDetailsTabs.defaultProps = {
    // props
    serialNumber: '',
    selectedTabId: '',
    isDeviceLogsUser: false,
    canReadSysParamDefinitions: false,
    controlDeviceEligiblePackageVersions: {},
    controlDeviceEligibleFileVersions: {},
    setSelectedTabDeviceDetailsSidebar: noop,
};

DeviceDetailsTabs.propTypes = {
    // props
    serialNumber: PropTypes.string,
    selectedTabId: PropTypes.string,
    isDeviceLogsUser: PropTypes.bool,
    canReadSysParamDefinitions: PropTypes.bool,
    controlDeviceEligiblePackageVersions: PropTypes.object,
    controlDeviceEligibleFileVersions: PropTypes.object,
    setSelectedTabDeviceDetailsSidebar: PropTypes.func,
};
