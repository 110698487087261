import noop from 'lodash/fp/noop';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { ALL } from '~/features/base/constants/filterOptions';
import without from 'lodash/fp/without';
import { FormattedMessage } from 'react-intl';
import Tag from '@rio-cloud/rio-uikit/lib/es/Tag';
import TagList from '@rio-cloud/rio-uikit/lib/es/TagList';

export class DeviceFilterDisplay extends PureComponent {
    updateFilter = (filters) => {
        this.props.updateFilter(filters);
    };
    removeHwVariant = () => {
        const { updateFilter } = this.props;
        updateFilter({
            hwVariant: undefined,
        });
    };
    removeHwVariantFromWhitelist = (hardwareVariant) => {
        const { updateFilter, defaultedQuery } = this.props;
        const updatedHardwareVariants = without([hardwareVariant], defaultedQuery.hwVariantList);
        updateFilter({
            hwVariantList: updatedHardwareVariants,
        });
    };
    removeDistroVersion = () => {
        const { updateFilter } = this.props;
        updateFilter({
            distroVersion: undefined,
        });
    };
    removeDeviceType = () => {
        const { updateFilter } = this.props;
        updateFilter({
            deviceType: undefined,
        });
    };
    removeBaseSoftwareVersion = () => {
        const { updateFilter } = this.props;
        updateFilter({
            swVersion: undefined,
        });
    };
    removeVehicleVariantFromWhitelist = (vehicleVariant) => {
        const { updateFilter, defaultedQuery } = this.props;
        const updatedVehicleVariants = without([vehicleVariant], defaultedQuery.vehicleVariantsWhitelist);
        updateFilter({
            vehicleVariantsWhitelist: updatedVehicleVariants,
        });
    };
    removeGroupFromWhitelist = (group) => {
        const { updateFilter, defaultedQuery } = this.props;
        const updatedGroups = without([group], defaultedQuery.vehicleGroupWhitelist);
        updateFilter({
            vehicleGroupWhitelist: updatedGroups,
        });
    };
    removeGroupFromBlacklist = (group) => {
        const { updateFilter, defaultedQuery } = this.props;
        const updatedGroups = without([group], defaultedQuery.vehicleGroupBlacklist);
        updateFilter({
            vehicleGroupBlacklist: updatedGroups,
        });
    };
    removeVehicleManufacturerSparePartNumber = () => {
        const { updateFilter } = this.props;
        updateFilter({
            vehicleManufacturerSparePartNumber: undefined,
        });
    };
    removeInCustomerFleet = () => {
        const { updateFilter } = this.props;
        updateFilter({
            inCustomerFleet: undefined,
        });
    };
    removeUpdatesActive = () => {
        const { updateFilter } = this.props;
        updateFilter({
            updatesActive: undefined,
        });
    };
    removeTestReleasesActive = () => {
        const { updateFilter } = this.props;
        updateFilter({
            testReleasesActive: undefined,
        });
    };
    removeDeviceStatusType = () => {
        const { updateFilter } = this.props;
        updateFilter({
            deviceStatusType: undefined,
        });
    };
    removeDeviceStatusNames = () => {
        const { updateFilter } = this.props;
        updateFilter({
            deviceStatusNames: undefined,
        });
    };
    removeLastReportedEssentialImage = () => {
        const { updateFilter } = this.props;
        updateFilter({
            lastEssentialImageReportedId: undefined,
            lastEssentialImageReportedVersion: undefined,

        });
    };

    render() {
        const { defaultedQuery } = this.props;
        const tags = [];
        if (defaultedQuery.hwVariant && defaultedQuery.hwVariant !== ALL) {
            tags.push(<Tag size='small' deletable
                           onClick={() => this.removeHwVariant()}>{`HW prefix: ${defaultedQuery.hwVariant}`}</Tag>);
        }
        if (defaultedQuery.hwVariantList) {
            Array.isArray(defaultedQuery.hwVariantList) ?
                defaultedQuery.hwVariantList.map(item => {
                    tags.push(
                        <Tag size='small' deletable onClick={() => this.removeHwVariantFromWhitelist(item)}>
                            {`HV: ${item}`}
                        </Tag>,
                    );
                })
                :
                tags.push(
                    <Tag size='small' deletable
                         onClick={() => this.removeHwVariantFromWhitelist(defaultedQuery.vehicleVariantsWhitelist)}>
                        {`HV: ${defaultedQuery.hwVariantList}`}
                    </Tag>,
                );
        }
        if (defaultedQuery.distroVersion && defaultedQuery.distroVersion !== ALL) {
            tags.push(<Tag size='small' deletable
                           onClick={() => this.removeDistroVersion()}>{`DV: ${defaultedQuery.distroVersion}`}</Tag>);
        }
        if (defaultedQuery.deviceType !== undefined) {
            tags.push(<Tag size='small' deletable
                           onClick={() => this.removeDeviceType()}>{`${defaultedQuery.deviceType}`}</Tag>);
        }
        if (defaultedQuery.swVersion && defaultedQuery.swVersion !== ALL) {
            tags.push(<Tag size='small' deletable
                           onClick={() => this.removeBaseSoftwareVersion()}>{`SV: ${defaultedQuery.swVersion}`}</Tag>);
        }
        if (defaultedQuery.vehicleManufacturerSparePartNumber !== undefined) {
            tags.push(<Tag size='small' deletable
                           onClick={() => this.removeVehicleManufacturerSparePartNumber()}>{`VMSPN: ${defaultedQuery.vehicleManufacturerSparePartNumber}`}</Tag>);
        }
        if (defaultedQuery.inCustomerFleet === true) {
            tags.push(<Tag size='small' deletable
                           onClick={() => this.removeInCustomerFleet()}><FormattedMessage
                id='intl-msg:inCustomerFleet'/></Tag>);
        }
        if (defaultedQuery.inCustomerFleet === false) {
            tags.push(<Tag size='small' deletable
                           onClick={() => this.removeInCustomerFleet()}><FormattedMessage
                id='intl-msg:notInCustomerFleet'/></Tag>);
        }
        if (defaultedQuery.updatesActive === true) {
            tags.push(<Tag size='small' deletable
                           onClick={() => this.removeUpdatesActive()}><FormattedMessage
                id='intl-msg:notBlocked'/></Tag>);
        }
        if (defaultedQuery.updatesActive === false) {
            tags.push(<Tag size='small' deletable
                           onClick={() => this.removeUpdatesActive()}><FormattedMessage id='intl-msg:blocked'/></Tag>);
        }
        if (defaultedQuery.testReleasesActive === true) {
            tags.push(<Tag size='small' deletable
                           onClick={() => this.removeTestReleasesActive()}><FormattedMessage
                id='intl-msg:testReleasesActive'/></Tag>);
        }
        if (defaultedQuery.testReleasesActive === false) {
            tags.push(<Tag size='small' deletable
                           onClick={() => this.removeTestReleasesActive()}><FormattedMessage
                id='intl-msg:testReleasesNotActive'/></Tag>);
        }
        if (defaultedQuery.deviceStatusType) {
            tags.push(
                <Tag size='small' deletable
                     onClick={() => this.removeDeviceStatusType()}>
                    <FormattedMessage id='intl-msg:deviceHealthTypes'/>
                    {`: ${defaultedQuery.deviceStatusType.toUpperCase()}`}
                </Tag>);
        }
        if (defaultedQuery.deviceStatusNames) {
            tags.push(
                <Tag size='small' deletable
                     onClick={() => this.removeDeviceStatusNames()}>
                    <FormattedMessage id='intl-msg:deviceStatus'/>
                    {`: ${defaultedQuery.deviceStatusNames.toUpperCase()}`}
                </Tag>);
        }
        if (defaultedQuery.vehicleVariantsWhitelist) {
            Array.isArray(defaultedQuery.vehicleVariantsWhitelist) ?
                defaultedQuery.vehicleVariantsWhitelist.map(item => {
                    tags.push(
                        <Tag size='small' deletable onClick={() => this.removeVehicleVariantFromWhitelist(item)}>
                            {`VV: ${item}`}
                        </Tag>,
                    );
                })
                :
                tags.push(
                    <Tag size='small' deletable
                         onClick={() => this.removeVehicleVariantFromWhitelist(
                             defaultedQuery.vehicleVariantsWhitelist)}>
                        {`VV: ${defaultedQuery.vehicleVariantsWhitelist}`}
                    </Tag>,
                );
        }
        if (defaultedQuery.vehicleGroupWhitelist) {
            Array.isArray(defaultedQuery.vehicleGroupWhitelist) ?
                defaultedQuery.vehicleGroupWhitelist.map(item => {
                    tags.push(
                        <Tag size='small' deletable onClick={() => this.removeGroupFromWhitelist(item)}>
                            {`WLG: ${item}`}
                        </Tag>,
                    );
                })
                :
                tags.push(
                    <Tag size='small' deletable
                         onClick={() => this.removeGroupFromWhitelist(defaultedQuery.vehicleGroupWhitelist)}>
                        {`WLG: ${defaultedQuery.vehicleGroupWhitelist}`}
                    </Tag>,
                );
        }
        if (defaultedQuery.vehicleGroupBlacklist) {
            Array.isArray(defaultedQuery.vehicleGroupBlacklist) ?
                defaultedQuery.vehicleGroupBlacklist.map(item => {
                    tags.push(
                        <Tag size='small' deletable onClick={() => this.removeGroupFromBlacklist(item)}>
                            {`BLG: ${item}`}
                        </Tag>,
                    );
                })
                :
                tags.push(
                    <Tag size='small' deletable
                         onClick={() => this.removeGroupFromBlacklist(defaultedQuery.vehicleGroupBlacklist)}>
                        {`BLG: ${defaultedQuery.vehicleGroupWhitelist}`}
                    </Tag>,
                );
        }
        if (defaultedQuery.lastEssentialImageReportedId) {
            const {
                lastEssentialImageReportedId: id,
                lastEssentialImageReportedVersion: version,
            } = defaultedQuery;
            tags.push(
                <Tag size='small' deletable
                     onClick={this.removeLastReportedEssentialImage}>
                    {version ? `SV: ${id}@${version}` : `SV:${id}`}
                </Tag>);
        }
        return (
            (tags.length > 0) &&
            <div className='display-flex row padding-left-10'>
                <div>
                    <FormattedMessage id='intl-msg:filteredBy'/>
                </div>
                <div>
                    <TagList className='padding-left-5'>
                        {
                            [
                                tags,
                            ]
                        }
                    </TagList>
                </div>
            </div>
        );
    }
}

export default DeviceFilterDisplay;

DeviceFilterDisplay.defaultProps = {
    // props
    defaultedQuery: {},
    // functions
    updateFilter: noop,
};

DeviceFilterDisplay.propTypes = {
    // props
    defaultedQuery: PropTypes.object,
    // functions
    updateFilter: PropTypes.func,
};
